<template>
  <div class="wrap-account-management">
    <TopNav
      :title="'am_title'"
      :navList="navList"
      v-model="tabView"
      @goTo="onGoTo"
      @openHelper="onOpenHelper"
    />
    <div class="container">
      <transition>
        <!-- <component :is="tabView" :staff="staff ? true : false"></component> -->
        <router-view/>
      </transition>
    </div>
    <PortalHelper
      v-if="showHelper"
      :content="helperPortal"
      @close="onCloseHelper"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
// import { PermissionLevel } from '@/config/permissions.js'
import {
  console
} from '@/utils/lib.js'
import { routeGo, accountPermission } from '@/router'

// import User from '@/components/AccountManagement/User.vue'
// import Device from '@/components/AccountManagement/Device.vue'
// import Group2 from '@/components/AccountManagement/Group2.vue'
// import Role from '@/components/AccountManagement/Role.vue'
import PortalHelper from '@/components/AccountManagement/portal/PortalHelper.vue'

export default {
  name: 'AccountManagement',
  components: {
    TopNav: () => import(`@/components/tools/TopNav.vue`),

    // User,
    // Device,
    // Group2,
    // Role,
    PortalHelper,
  },
  data() {
    return {
      tabView: null,
      showHelper: false,
      helperPortal: null
    }
  },
  computed: {
    ...mapState(['staff', 'permissionV2']),
    navList() {
      const { user, device, group, role } = accountPermission(this.permissionV2)
      const list = []
      if (user || this.staff) {
        list.push({
          navTitle: 'am_user',
          comp: 'User',
          helper: null
        })
      }
      if (device || this.staff) {
        list.push({
          navTitle: 'am_device',
          comp: 'Device',
          helper: null
        })
      }
      if (group || this.staff) {
        list.push({
          navTitle: 'am_group',
          comp: 'Group',
          // dev: true,
          helper: null
        })
      }

      if (role || this.staff) {
        list.push({
          navTitle: 'am_role',
          comp: 'Role',
          helper: {
            title: 'am_role_helper_title',
            intro: 'am_role_helper_intro',
            desc: [
              {
                str: 'am_role_helper_desc1',
                subDesc: [
                  'am_role_helper_desc1_sub1',
                  'am_role_helper_desc1_sub2'
                ]
              },
              { str: 'am_role_helper_desc2' /*, subDesc:[]*/ }
            ]
          }
        })
      }

      return list
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        const tab = this.navList.find((_t) => {
          const key = String(_t.comp).toLowerCase()
          const path = this.$route.path.split('/').pop()

          return path.includes(key)
        })

        this.tabView = tab ? tab.comp : null
      }
    }
  },
  methods: {
    ...mapActions('userinfo', ['fetchAccountManageList']),
    ...mapMutations('userinfo', [
      'updateGroupId',
      'updateFilterText',
      'updateEditMode',
      'updateUserId',
      'updateAccountPortal'
    ]),
    onGoTo(comp) {
      if (this.tabView !== comp) {
        this.tabView = comp
        routeGo(`/account-management/${comp.toLowerCase()}`)
        this.fetchAccountManageList()
      }
    },
    onOpenHelper(tab) {
      if (this.showHelper) {
        this.onCloseHelper()
      } else {
        this.showHelper = true
        this.helperPortal = { ...tab.helper }
      }
      this.updateAccountPortal('account-helper')
    },
    onCloseHelper() {
      this.showHelper = false
      this.helperPortal = null
    }
  },
  async created() {
    // 取得各列表
    console.log(`[AccountManagement] created`)
    await this.fetchAccountManageList()
  },
  async mounted() {
    console.set(false) // 在 created() 執行會沒有設定到
  },
}
</script>

<style lang="scss" scoped>
.wrap-account-management {
  width: calc(100% - px2rem(50));
  height: 100vh;
  display: grid;
  grid-template-rows: px2rem($NavH) calc(100% - px2rem($NavH));
  // grid-template-columns: 50px calc(100% - px2rem(50));
  grid-template-columns: 0 100%;
  grid-template-areas:
    '. navbar'
    '. container';
}

.sidebar {
  grid-area: sidebar;
}

// .navbar > .select-tab {
//   color: #ffb703;
//   border-color: #ffb703;
//   transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
// }

.container {
  grid-area: container;
  background-color: #282942;
  height: calc(100vh - px2rem($NavH));
}
</style>
